<template>
  <div id="app">
    <div class="headerBox" :style="indexFlag ? 'background-color: #fff;' : ''">
      <Head class="header"></Head>
    </div>
    <div class="main">
      <router-view :class="indexFlag ? 'mgt70' : ''" />
    </div>
    <div class="footer">
      <FooterItem class="footer_center"></FooterItem>
    </div>
  </div>
</template>

<script>
import Head from "./components/Head";
import FooterItem from "@/components/Footer";

export default {
  name: "App",
  components: {
    Head,
    FooterItem,
  },
  created() {},
  data() {
    const self = this;
    return {
      center: [121.59996, 31.197646],
      lng: 0,
      lat: 0,
      loaded: false,
      plugin: [
        {
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 100, //超过10秒后停止定位，默认：无穷大
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: "RB", //定位按钮停靠位置，默认：'LB'，左下角
          showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          extensions: "all",
          pName: "Geolocation",
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                window.localStorage.setItem(
                  "cityName",
                  result.addressComponent.city
                );
                window.localStorage.setItem("lat", result.position.lat);
                window.localStorage.setItem("lng", result.position.lng);
                if (result && result.position) {
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.center = [self.lng, self.lat];
                  self.loaded = true;
                  self.$nextTick();
                }
              });
            },
          },
        },
      ],
      indexFlag: true,
    };
  },
  methods: {
    scrollToTop() {
      document.body.scrollIntoView();
    },
    toJSON() {
      return this;
    },
  },
  created() {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (isMobile) {
      // 如果是移动端，跳转到移动端链接
      window.location.href = "https://mobi.kuangzhen.com.cn";
    } else {
      // 如果是PC端，跳转到PC端链接
      // window.location.href = 'https://f.kzspp.com/cms';
    }
    if (this.$route.name == "index" || this.$route.name == "marriage" || this.$route.name == "criminal" || this.$route.name == "debtRestructuring") {
      this.indexFlag = false;
    } else {
      this.indexFlag = true;
    }
  },
  watch: {
    $route(to) {
      this.scrollToTop();
      if (
        to.name == "shopList" ||
        to.name == "index" ||
        to.name == "map" ||
        to.name == "my" ||
        to.name == "oilShow"
      ) {
        this.tabbarShow = true;
      } else {
        this.tabbarShow = false;
      }
    },
    "$route.path": function (newVal, oldVal) {
      if (newVal == "/index" || newVal == "/marriage" || newVal == "/criminal" || newVal == "/debtRestructuring") {
        this.indexFlag = false;
      } else {
        this.indexFlag = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.headerBox {
  position: fixed;
  left: 0%;
  top: 0;
  width: 100vw;
  height: 70px;
  z-index: 999;
}
#app {
  position: relative;
  padding: 0;
  margin: 0;
  background-color: #f7f8fa;
}
.header {
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: space-evenly;
  min-width: 1080px;
}
.main {
  top: 0;
  left: 0;
  max-width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
}
.mgt70 {
  padding-top: 70px;
  // margin-top: 70px;
  background-color: #fff;
  padding-bottom: 80px;
}

.footer {
  height: 160px;
  background: rgb(19, 19, 19);
  padding: 15px 0;
  text-align: center;
  color: rgb(141, 141, 141);
  display: table;
  width: 100%;
}
.footer_center {
  text-align: center;
}
</style>

// 导入组件
import Vue from 'vue';
import Router from 'vue-router';

// 启用路由
Vue.use(Router);

// 导入路由

// 首页
// import index from '../pages/home/index'

// 导出路由 
export default new Router({
    mode: 'history',
    routes: [
        {
            name: 'index',
            path: '',
            redirect: '/index',
            meta: {
                title: '湖南旷真律师事务所'
            }
        },
        {
            name: 'index',
            path: '/index',
            component: () =>
                import('@/pages/home/index'),
            meta: {
                title: '湖南旷真律师事务所'
            }
        },
        // 关于旷真
        {
            name: 'about',
            path: '/about',
            component: () =>
                import('@/pages/about/index'),
            meta: {
                title: '关于旷真 | 湖南旷真律师事务所'
            }
        },
        // 旷真知产
        {
            name: 'intellectualProperty',
            path: '/intellectualProperty',
            component: () =>
                import('@/pages/intellectualProperty/index'),
            meta: {
                title: '旷真知产 | 湖南旷真律师事务所'
            }
        },
        // 旷真应收款
        {
            name: 'business',
            path: '/business',
            component: () =>
                import('@/pages/business/index'),
            meta: {
                title: '旷真应收款 | 湖南旷真律师事务所'
            }
        },
        // 旷真民生
        {
            name: 'ms',
            path: '/ms',
            component: () =>
                import('@/pages/ms/index'),
            meta: {
                title: '旷真民生 | 湖南旷真律师事务所'
            }
        },
        // 新闻动态
        {
            name: 'news',
            path: '/news',
            component: () =>
                import('@/pages/news/index'),
            meta: {
                title: '新闻动态 | 文章中心 | 湖南旷真律师事务所'
            }
        },
        // 加入旷真
        {
            name: 'joinUs',
            path: '/joinUs',
            component: () =>
                import('@/pages/joinUs/index'),
            meta: {
                title: '加入旷真 | 湖南旷真律师事务所'
            }
        },
        // 联系我们
        {
            name: 'contactUs',
            path: '/contactUs',
            component: () =>
                import('@/pages/contactUs/index'),
            meta: {
                title: '联系我们 | 湖南旷真律师事务所'
            }
        },
        // 人才成长
        {
            name: 'talentGrowth',
            path: '/talentGrowth',
            component: () =>
                import('@/pages/joinUs/talentGrowth'),
            meta: {
                title: '文章中心 | 湖南旷真律师事务所'
            }
        },
        // 新闻详情
        {
            name: 'newsMsg',
            path: '/newsMsg',
            component: () =>
                import('@/pages/news/newsMsg'),
            meta: {
                title: '新闻详情 | 湖南旷真律师事务所'
            }
        },
        // 应收款
        {
            name: 'receivables',
            path: '/receivables',
            component: () =>
                import('@/pages/business/receivables'),
            meta: {
                title: '应收款 | 湖南旷真律师事务所'
            }
        },
        // 婚姻家事
        {
            name: 'marriage',
            path: '/marriage',
            component: () =>
                import('@/pages/business/marriage'),
            meta: {
                title: '婚姻家事 | 湖南旷真律师事务所'
            }
        },
        // 刑事辩护
        {
            name: 'criminal',
            path: '/criminal',
            component: () =>
                import('@/pages/business/criminal'),
            meta: {
                title: '刑事辩护 | 湖南旷真律师事务所'
            }
        },
        // 物业法律服务
        {
            name: 'propertyLegalServices',
            path: '/propertyLegalServices',
            component: () =>
                import('@/pages/business/propertyLegalServices'),
            meta: {
                title: '物业法律服务 | 湖南旷真律师事务所'
            }
        },
        // 债务重组
        {
            name: 'debtRestructuring',
            path: '/debtRestructuring',
            component: () =>
                import('@/pages/business/debtRestructuring'),
            meta: {
                title: '债务重组 | 湖南旷真律师事务所'
            }
        }
    ]
})
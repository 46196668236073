<template>
  <div
    class="d-flex"
    :style="$route.path == '/index' ? '' : 'background-color: #fff;'"
  >
    <div>
      <img :src="$route.path == '/index' ? src : srcImages" />
    </div>
    <div class="title_Tabs">
      <div class="d-flex">
        <div v-for="item in tabList" class="set-tabs-style" :class="`${$route.path == '/index' ? 'color-tab-one' : 'color-tab-two'}`">
          <el-dropdown v-if="item.name == '业务领域'" @command="handleCommand" placement="bottom">
            <div
              :class="`set-tabs-item ${businessList.includes($route.path) ? 'is-active' : ''} ${$route.path == '/index' ? 'color-tab-one' : 'color-tab-two'}`"
              style="fontSize: 16px;"
              @click="toOtherPage(item)">
              {{item.name}}
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="childItem in item.childList"
                :key="childItem.key"
                :command="childItem.path"
              >
                <span :class="`set-tabs-item ${$route.path == childItem.path ? 'dropdown-is-active' : ''}`">{{childItem.name}}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div
            v-else
            @click="toOtherPage(item)"
            :class="`set-tabs-item ${$route.path == item.path ? 'is-active' : ''}`">
            {{item.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/Image/logo001.png";
export default {
  data() {
    return {
      src: logo,
      srcImages: "https://f.kzspp.com/cms/new/theme/kz/images/logo002.png",
      businessList: [ //业务领域下拉路由路径
        "/receivables", // 应收款
        "/marriage", //婚姻家事
        "/criminal", //刑事辩护
        "/debtRestructuring", //债务重组
        "/propertyLegalServices", //物业法律顾问
      ],
      // tabValue: "tab1",
      tabList: [
        {
          name: "首页",
          key: "tab1",
          path: "/index",
        },
        {
          name: "关于旷真",
          key: "tab2",
          path: "/about",
        },
        {
          name: "业务领域",
          key: "tab9",
          path: "/receivables",
          childList: [
            {
              name: "应收款",
              key: "tab9-1",
              path: "/receivables",
            },
            {
              name: "婚姻家事",
              key: "tab9-2",
              path: "/marriage",
            },
            {
              name: "刑事辩护",
              key: "tab9-3",
              path: "/criminal",
            },
            {
              name: "债务重组",
              key: "tab9-4",
              path: "/debtRestructuring",
            },
            {
              name: "物业法律顾问",
              key: "tab9-5",
              path: "/propertyLegalServices",
            }
          ]
        },
        {
          name: "新闻动态",
          key: "tab6",
          path: "/news",
        },
        {
          name: "加入旷真",
          key: "tab7",
          path: "/joinUs",
        },
        {
          name: "联系我们",
          key: "tab8",
          path: "/contactUs",
        }
      ]
    };
  },
  mounted() {},
  methods: {
    toOtherPage(item) {
      if (item.key != "tab9") {
        if (item.path == this.$route.path) {
          window.location.reload();
        } else {
          this.$router.push(item.path);
        }
      }
    },
    handleCommand(value) {
      if (value == this.$route.path) {
        window.location.reload();
      } else {
        this.$router.push(value);
      }
    }
  }
};
</script>

<style scoped>
.d-flex {
  display: flex;
}
.title_Tabs {
  padding-left: 110px;
  letter-spacing: 2px;
  font-size: 16px;
}
.title_Tabs >>> .el-tabs__nav-wrap::after {
  position: static !important;
}
.title_Tabs,
.el-tabs,
.title_Tabs >>> .el-tabs__header,
.title_Tabs >>> .is-top {
  height: 48px;
}
.color-tab-one {
  color: #fff;
}
.color-tab-two {
  color: #000;
}
.title_Tabs >>> .el-tabs__item {
  font-size: 16px;
  line-height: 58px;
}
.title_Tabs >>> .is-active {
  position: relative;
  color: #f44336;
}
.title_Tabs >>> .is-active::after {
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 100%;
  background-color: #f44336;
  content: "";
  z-index: 999999;
}
.title_Tabs >>> .el-tabs__item:nth-child(2).is-active::after {
  /* width: ; */
  left: 25%;
}
.title_Tabs >>> .el-tabs__active-bar.is-top {
  background: none !important;
}
.title_Tabs >>> .set-tabs-item:hover {
  color: #f44336;
  cursor: pointer;
}
.set-tabs-style {
  margin-top: 12px;
  margin-right: 28px;
  line-height: 36px;
  font-size: 16px;
  cursor: pointer;
}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fbe8e6;
  color: #f44336;
}
.dropdown-is-active {
  color: #f44336;
}
</style>
